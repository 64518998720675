* {
  box-sizing: border-box;
}

html, body, #root, .App, .ContentContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  background-color: var(--site-background); /* Use theme background */
}

/* Network background canvas styling */
.network-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  will-change: transform, opacity; /* Optimize for animations and opacity changes */
  transform: translateZ(0); /* Force GPU acceleration */
  filter: blur(0.5px); /* Slight blur for softness */
  transition: opacity 0.5s ease;
  opacity: 0.85; /* Slightly toned down */
}

/* Optimize rendering performance for the network particles */
@media (prefers-reduced-motion: no-preference) {
  .network-background {
    animation: fade-in 0.8s ease-out;
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 0.85; }
}

/* Content container for about page */
.about-content {
  width: 100%;
  max-width: 1000px;
  margin: 70px auto 30px; /* Slightly increased bottom margin */
  padding: 30px 30px 15px; /* Slightly increased bottom padding */
  position: relative;
  border-radius: 16px; /* More rounded corners */
  overflow: hidden;
  /* Theme-dependent styling handled by CSS variables */
  background-color: var(--about-content-bg);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: var(--about-content-shadow);
  border: var(--about-content-border);
  transition: all 0.4s ease-out;
}

.about-content:hover {
  transform: translateY(-5px);
  box-shadow: var(--about-content-hover-shadow);
}

/* Set different styles based on theme */
html[style*="--background: #f1f2f6"] .about-content {
  --about-content-bg: rgba(255, 255, 255, 0.75);
  --about-content-shadow: 0 8px 30px rgba(0, 0, 0, 0.1), 0 0 15px rgba(100, 100, 255, 0.1);
  --about-content-hover-shadow: 0 15px 40px rgba(0, 0, 0, 0.15), 0 0 20px rgba(100, 100, 255, 0.2);
  --about-content-border: 1px solid rgba(0, 0, 0, 0.08);
  --section-bg: rgba(245, 247, 250, 0.7);
  --section-hover-bg: rgba(255, 255, 255, 0.9);
  --section-border: 1px solid rgba(0, 0, 0, 0.05);
  --item-bg: rgba(240, 242, 245, 0.7);
  --item-hover-bg: rgba(255, 255, 255, 0.8);
  --separator-glow: rgba(52, 152, 219, 0.5);
}

html[style*="--background: #161b2c"] .about-content {
  --about-content-bg: rgba(15, 23, 42, 0.65);
  --about-content-shadow: 0 10px 30px rgba(0, 0, 0, 0.3), 0 0 15px rgba(142, 68, 173, 0.2);
  --about-content-hover-shadow: 0 15px 40px rgba(0, 0, 0, 0.4), 0 0 20px rgba(142, 68, 173, 0.3);
  --about-content-border: 1px solid rgba(255, 255, 255, 0.1);
  --section-bg: rgba(30, 41, 59, 0.5);
  --section-hover-bg: rgba(30, 41, 59, 0.7);
  --section-border: 1px solid rgba(255, 255, 255, 0.05);
  --item-bg: rgba(0, 0, 0, 0.2);
  --item-hover-bg: var(--theme-hoverBackground);
  --separator-glow: rgba(142, 68, 173, 0.5);
}

.AboutPageHeader {
  text-align: center;
  margin-bottom: 40px;
}

.AboutPageName {
  font-size: 3em;
  color: var(--text);
  margin: 0;
  text-shadow: 0 0 15px var(--separator-glow);
  position: relative;
  display: inline-block;
}

.AboutPageName::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(90deg, transparent, var(--linkColor), transparent);
  border-radius: 3px;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; box-shadow: 0 0 5px var(--separator-glow); }
  50% { opacity: 1; box-shadow: 0 0 10px var(--separator-glow); }
  100% { opacity: 0.6; box-shadow: 0 0 5px var(--separator-glow); }
}

.AboutPageSubTitle {
  font-size: 1.3em;
  color: var(--text);
  opacity: 0.9;
  margin-top: 20px;
  letter-spacing: 1px;
}

.AboutPageSection {
  margin-bottom: 50px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 25px;
  border-radius: 12px;
  background-color: var(--section-bg);
  border: var(--section-border);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
}

.AboutPageSection:hover {
  transform: translateY(-5px);
  background-color: var(--section-hover-bg);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 0 10px var(--separator-glow);
}

.AboutPageSectionTitle {
  font-size: 2em;
  color: var(--text);
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: inline-block;
}

.AboutPageSeparator {
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, var(--linkColor) 0%, var(--separator-glow) 50%, var(--linkColor) 100%);
  background-size: 200% 100%;
  animation: scrollGradient 8s linear infinite;
  box-shadow: 0 0 8px var(--separator-glow);
  margin: 0 0 20px 0;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.AboutPageSeparator::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7), transparent);
  animation: shine 8s infinite;
}

@keyframes scrollGradient {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 200% 100%;
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

/* Gleaming effect animation for particles */
@keyframes gleam {
  0% {
    filter: brightness(1) saturate(1);
  }
  50% {
    filter: brightness(1.3) saturate(1.3);
  }
  100% {
    filter: brightness(1) saturate(1);
  }
}

.AboutPageText {
  font-size: 1.1em;
  color: var(--text);
  line-height: 1.6;
  transition: color var(--transition-duration) ease;
}

.AboutPageList {
  list-style: none;
  padding: 0;
}

.AboutPageListItem {
  margin-bottom: 15px;
  font-size: 1.1em;
  color: var(--text);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--item-bg);
  transition: all 0.3s ease;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
  line-height: 1.6;
}

.AboutPageListItem::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, var(--linkColor), var(--separator-glow));
  opacity: 0.7;
  transition: all 0.3s ease;
}

.AboutPageListItem:hover {
  transform: translateX(5px);
  background: var(--item-hover-bg);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1), 0 0 5px var(--separator-glow);
  border-color: rgba(var(--linkColor-rgb), 0.1);
}

.AboutPageListItem:hover::before {
  width: 6px;
  opacity: 1;
}

.AboutPageContactLink {
  color: var(--linkColor);
  text-decoration: none;
  font-weight: bold;
  position: relative;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: rgba(var(--linkColor-rgb), 0.1);
}

.AboutPageContactLink:hover {
  background-color: rgba(var(--linkColor-rgb), 0.2);
  box-shadow: 0 0 10px rgba(var(--linkColor-rgb), 0.3);
  transform: translateY(-2px);
}

/* Improved media queries for better responsive design */
@media screen and (max-width: 1024px) {
  .about-content {
    max-width: 90%;
    margin-top: 90px; /* Adjust for medium-large screens */
    padding: 25px;
  }
  
  .AboutPageName {
    font-size: 2.5em;
  }
  
  .AboutPageSubTitle {
    font-size: 1.2em;
  }
  
  .AboutPageSection {
    padding: 20px;
  }
  
  .AboutPageSectionTitle {
    font-size: 1.8em;
  }
  
  .AboutPageListItem, .AboutPageText {
    font-size: 1.05em;
  }
}

@media screen and (max-width: 768px) {
  .about-content {
    max-width: 95%;
    margin-top: 80px; /* Maintain consistent spacing */
    padding: 20px;
    transform: none !important;
  }
  
  .AboutPageName {
    font-size: 2.2em;
  }
  
  .AboutPageSubTitle {
    font-size: 1.1em;
    letter-spacing: 0.5px;
  }
  
  .AboutPageSection {
    padding: 18px;
    margin-bottom: 30px;
    transform: none !important;
  }
  
  .AboutPageSectionTitle {
    font-size: 1.6em;
  }
  
  .AboutPageSeparator {
    margin-bottom: 15px;
  }
  
  .AboutPageListItem, .AboutPageText {
    font-size: 1em;
    padding: 12px;
  }
}

@media screen and (max-width: 480px) {
  .about-content {
    margin-top: 70px;
    padding: 15px;
    border-radius: 12px;
  }
  
  .AboutPageHeader {
    margin-bottom: 25px;
  }
  
  .AboutPageName {
    font-size: 1.8em;
  }
  
  .AboutPageSubTitle {
    font-size: 0.95em;
    letter-spacing: 0;
    margin-top: 20px;
    padding: 0 10px;
  }
  
  .AboutPageSection {
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
  }
  
  .AboutPageSectionTitle {
    font-size: 1.4em;
    padding-bottom: 5px;
  }
  
  .AboutPageListItem {
    font-size: 0.95em;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .AboutPageListItem::before {
    width: 3px;
  }
  
  .AboutPageListItem:hover {
    transform: none;
  }
}

/* Very small screens */
@media screen and (max-width: 400px) {
  .about-content {
    margin-top: 60px; /* Reduced spacing for tiny screens */
    padding: 12px;
    border-radius: 10px;
  }
  
  .AboutPageHeader {
    margin-bottom: 20px;
  }
  
  .AboutPageName {
    font-size: 1.6em;
  }
  
  .AboutPageSubTitle {
    font-size: 0.9em;
    padding: 0 5px;
    margin-top: 10px;
  }
  
  .AboutPageSection {
    padding: 12px;
    margin-bottom: 20px;
  }
}

/* Add some extra spacing at the bottom to ensure scrolling shows more content */
.about-content::after {
  content: '';
  display: block;
  height: 20px; /* Added a small amount of spacing */
  width: 100%;
}

/* Add CSS variables for RGB values of link color for opacity use */
:root {
  --linkColor-rgb: 52, 152, 219; /* Blue for light mode */
}

html[style*="--background: #161b2c"] {
  --linkColor-rgb: 142, 68, 173; /* Purple for dark mode */
}

/* Last section should have less bottom margin since we have the ::after element */
.AboutPageSection:last-of-type {
  margin-bottom: 15px; /* Slightly increased margin */
}