.nav-bar-container {
  background-color: rgba(var(--navbar-background-rgb), 0.85);
  padding: 12px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  transition: all var(--transition-duration) ease;
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 90vw;
  z-index: 1000;
  visibility: visible;
  opacity: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateZ(0);
}

/* Media query for medium devices (tablets) */
@media (max-width: 768px) {
  .nav-bar-container {
    padding: 10px 20px;
    border-radius: 25px;
    top: 20px;
    max-width: 95vw;
  }
}

/* Media query for small tablets */
@media (max-width: 600px) {
  .nav-bar-container {
    padding: 8px 18px;
    border-radius: 22px;
    top: 18px;
  }
}

/* Media query for small devices (mobile phones) */
@media (max-width: 480px) {
  .nav-bar-container {
    padding: 8px 15px;
    border-radius: 20px;
    top: 15px;
    max-width: 98vw;
  }
}

@media (max-width: 400px) {
  .nav-bar-container {
    padding: 6px 12px;
    border-radius: 18px;
    top: 12px;
  }
}

.nav-bar-container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transform: translateY(-20px);
}

.nav-bar-container.theme-toggled {
  transition: background-color var(--transition-duration) ease, 
              box-shadow var(--transition-duration) ease,
              transform var(--transition-duration) ease;
}

.nav-links {
  display: flex;
  gap: 35px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 768px) {
  .nav-links {
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .nav-links {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .nav-links {
    gap: 10px;
  }
}

@media (max-width: 400px) {
  .nav-links {
    gap: 5px;
  }
}

.nav-links > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  position: relative;
}

.base-nav-link {
  color: var(--color);
  text-decoration: none;
  font-size: clamp(0.85rem, 2.5vw, 1rem);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  padding: 8px 15px;
  position: relative;
  letter-spacing: 0.5px;
  border-radius: 12px;
  width: 100%;
  display: block;
}

@media (max-width: 768px) {
  .base-nav-link {
    font-size: clamp(0.85rem, 2vw, 0.95rem);
    padding: 6px 10px;
    letter-spacing: 0.3px;
  }
}

@media (max-width: 600px) {
  .base-nav-link {
    font-size: clamp(0.8rem, 1.8vw, 0.9rem);
    padding: 5px 8px;
    letter-spacing: 0.2px;
  }
}

@media (max-width: 480px) {
  .base-nav-link {
    font-size: clamp(0.75rem, 1.5vw, 0.85rem);
    padding: 5px 6px;
    letter-spacing: 0;
  }
}

@media (max-width: 400px) {
  .base-nav-link {
    font-size: clamp(0.7rem, 1.2vw, 0.8rem);
    padding: 4px 4px;
  }
}

.base-nav-link:hover {
  color: var(--linkColor);
  transform: translateY(-2px);
  background-color: rgba(var(--link-hover-bg-rgb), 0.08);
}

.base-nav-link:hover::after {
  content: '';
  position: absolute;
  width: 60%;
  height: 2px;
  background-color: var(--linkColor);
  bottom: 2px;
  left: 20%;
  opacity: 0.4;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

@media (max-width: 480px) {
  .base-nav-link:hover::after {
    display: none;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
    transform: scaleX(0.95);
  }
  50% {
    opacity: 0.7;
    transform: scaleX(1.05);
  }
  100% {
    opacity: 0.4;
    transform: scaleX(0.95);
  }
}

.base-nav-link.active {
  color: var(--linkColor);
  font-weight: 600;
  letter-spacing: 0.6px;
  text-shadow: 0 0 10px rgba(var(--link-glow-rgb), 0.5);
}

@media (max-width: 480px) {
  .base-nav-link.active {
    letter-spacing: 0.4px;
  }
}

.base-nav-link.active:hover::after {
  display: none;
}

.active-dot {
  width: 16px;
  height: 3px;
  background: linear-gradient(90deg, rgba(255,71,87,0.4) 0%, rgba(255,71,87,1) 50%, rgba(255,71,87,0.4) 100%);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(255, 71, 87, 0.4);
  pointer-events: none;
  z-index: 100;
}

@media (max-width: 480px) {
  .active-dot {
    width: 12px;
    height: 2px;
  }
}

.active-dot.initial {
  transition: none;
}

.nav-links > div:hover .active-dot {
  /* Make sure the indicator stays visible when hovering */
  opacity: 1 !important;
}

/* Hover effect for the navbar itself */
.nav-bar-container:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

@media (max-width: 480px) {
  .nav-bar-container:hover {
    transform: translateY(-2px);
  }
}