/* Add or update these styles for better mobile experience */
html, body {
  overflow-x: hidden;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  transition: background-color var(--transition-duration) ease, color var(--transition-duration) ease;
  background-color: var(--site-background);
  color: var(--color);
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  scrollbar-width: none; /* Disable Scrollbar on Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.AppContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}

.AppContainer.has-navbar {
  padding-top: 40px;
}

.ContentContainer {
  flex: 1;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 10px;
  box-sizing: border-box;
}

/* Theme toggle button styling */
.ThemeToggleButton {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--navbar-background);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.ThemeToggleButton:hover {
  transform: scale(1.1);
}

.ThemeToggleButton:active {
  transform: scale(0.95);
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .ThemeToggleButton {
    width: 35px;
    height: 35px;
    font-size: 18px;
    top: 15px;
    right: 15px;
  }
  
  .AppContainer {
    padding-top: 20px;
  }
  
  .AppContainer.has-navbar {
    padding-top: 30px;
  }
}

@media (max-width: 480px) {
  .ThemeToggleButton {
    width: 30px;
    height: 30px;
    font-size: 16px;
    top: 10px;
    right: 10px;
  }
  
  .ContentContainer {
    padding: 0 5px;
  }
}

/* Make sure touch interactions work properly on mobile */
@media (hover: none) {
  .ThemeToggleButton:hover {
    transform: none;
  }
  
  .ThemeToggleButton:active {
    transform: scale(0.95);
  }
}