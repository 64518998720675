:root {
  --site-background: #f1f2f6;
  --navbar-background: #f1f2f6;
  --background: #f1f2f6;
  --linkColor: #ff4757;
  --color: #1e272e;
  --text: #000000;
  --transition-duration: 0.3s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}